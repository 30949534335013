


















export default

	props: block: Object

	computed:
		cards: -> (@block.cards || []).filter (card) -> !!card.id

