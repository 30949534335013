
































































import ProductCard from '~/components/globals/cards/product/product-card'
import { mergeShopifyProductCards } from 'library/helpers/products'

export default

	props:
		card: Object

		imageStyle:
			type: String
			default: 'cover'

		noHover:
			type: Boolean
			default: false

		layout:
			type: String
			default: 'text-on-bottom'
			validator: (val) -> val in ['text-on-top', 'text-on-bottom']

	components: { ProductCard }

	data: ->
		product: null

	fetch: ->
		@product = (await mergeShopifyProductCards @card.product)?[0]

	computed:
		classes: -> [
			'no-hover' if @noHover
			'hover' if !@noHover
		]

		objectFit: -> @imageStyle

