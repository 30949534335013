import { render, staticRenderFns } from "./card-grid.vue?vue&type=template&id=c3d2bb4e&scoped=true&lang=pug&"
import script from "./card-grid.vue?vue&type=script&lang=coffee&"
export * from "./card-grid.vue?vue&type=script&lang=coffee&"
import style0 from "./card-grid.vue?vue&type=style&index=0&id=c3d2bb4e&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3d2bb4e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OlioNuovoLines: require('/opt/build/repo/library/components/globals/olio-nuovo-lines.vue').default,CardsBigCard: require('/opt/build/repo/nuxt-app/components/globals/cards/big-card.vue').default})
